
import { Component, Vue } from 'vue-property-decorator';
import { AdminService } from '@/services';

// User Info
import { UserModule } from '@/store/modules';

// Types
import { IGlobalSettings, MultipleCauseType } from '@/types/admin.types';
import { IAuthUser } from '@/types/user.types';
import dayjs from 'dayjs';

@Component({})
export default class GlobalSettings extends Vue {
  globalSettings: IGlobalSettings[] = [];

  editedIndex = -1;
  dialog = false;
  isUpdateSettings = false;
  isTestEmailSMSVisible = false;

  headers = [
    { text: 'NAME', value: 'name' },
    { text: 'VALUE', value: 'value' },
    { text: 'DESCRIPTION', value: 'description' },
    { text: 'TYPE', value: 'type' },
    { text: 'ACTIONS', value: 'actions', sortable: false }
  ];

  defaultData = {
    emailSubject: '',
    additionalInfo: null,
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    description: '',
    name: '',
    type: '',
    value: '',
    multipleCauseType: null
  };

  multipleCauseTye = [
    {
      text: 'Offers Only',
      value: MultipleCauseType.OFFER
    },
    {
      text: 'Round Up Only',
      value: MultipleCauseType.ROUND_UP
    },
    {
      text: 'Both',
      value: MultipleCauseType.BOTH
    }
  ];

  testAmount = 0;
  testMonth = new Date();
  testLast4Digits = 0;
  isSendingTestEmail = false;
  isSendingTestSMS = false;
  syncLoading = false;

  editedItem: IGlobalSettings = this.defaultData;
  defaultItem: IGlobalSettings = this.defaultData;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  editItem(item: IGlobalSettings): void {
    this.isTestEmailSMSVisible = false;
    this.testAmount = 0;
    this.testMonth = new Date();
    this.testLast4Digits = 0;
    this.editedIndex = this.globalSettings.indexOf(item);
    if (item.type == 'feature') {
      this.editedItem = Object.assign({}, item, { value: String(item.value).toLowerCase() === 'true' });
    } else {
      this.editedItem = Object.assign({}, item);
    }
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  async save(): Promise<void> {
    this.isUpdateSettings = true;
    await AdminService.updateGlobalSetting(
      {
        value: String(this.editedItem.value),
        emailSubject: this.editedItem.emailSubject,
        multipleCauseType: this.editedItem.multipleCauseType
      },
      this.editedItem.id
    );
    this.isUpdateSettings = false;
    this.close();
    this.getGlobalSettings();

    if (this.editedItem.name === 'multiple_causes') {
      window.location.reload();
    }
  }

  async getGlobalSettings(): Promise<void> {
    try {
      this.globalSettings = await AdminService.fetchGlobalSettings();
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  processTestSMSEmail(): void {
    this.isTestEmailSMSVisible = !this.isTestEmailSMSVisible;
  }

  async sendTestEmail(): Promise<any> {
    this.isSendingTestEmail = true;

    if (!this.editedItem.emailSubject) {
      this.isSendingTestEmail = false;
      this.$notifyError('Email Subject is required');
    }

    if (
      this.editedItem.name === 'email_template_charge_executed' ||
      this.editedItem.name === 'email_template_notification_charge'
    ) {
      if (!this.testAmount || !this.testMonth || !this.testLast4Digits) {
        this.isSendingTestEmail = false;
        this.$notifyError('All test email inputs are required');
      }

      const testEmailPayload = {
        body: this.editedItem.value,
        emailParams: {
          chargeAmount: Number(this.testAmount),
          chargeMonth: dayjs(this.testMonth).toDate(),
          last4Digits: String(this.testLast4Digits)
        },
        emailType: this.editedItem.name,
        subject: this.editedItem.emailSubject as string
      };
      await AdminService.sendTestEmail(testEmailPayload);
    } else if (
      this.editedItem.name === 'email_template_round_up_summary' ||
      this.editedItem.name === 'email_template_round_up_transaction'
    ) {
      if (!this.testAmount) {
        this.isSendingTestEmail = false;
        this.$notifyError('All test email inputs are required');
      }

      const testEmailPayload = {
        body: this.editedItem.value,
        emailParams: { roundedAmount: Number(this.testAmount) },
        emailType: this.editedItem.name,
        subject: this.editedItem.emailSubject as string
      };
      await AdminService.sendTestEmail(testEmailPayload);
    }
    this.isSendingTestEmail = false;
  }

  async sendTestSms(): Promise<void> {
    this.isSendingTestSMS = true;

    if (
      this.editedItem.name === 'sms_template_charge_executed' ||
      this.editedItem.name === 'sms_template_charge_warning'
    ) {
      if (!this.testAmount || !this.testMonth || !this.testLast4Digits) {
        this.isSendingTestSMS = false;
        this.$notifyError('All test sms inputs are required');
      }

      const testSmsPayload = {
        sms: this.editedItem.value,
        smsParams: {
          chargeAmount: Number(this.testAmount),
          chargeMonth: dayjs(this.testMonth).toDate(),
          last4Digits: String(this.testLast4Digits)
        },
        smsType: this.editedItem.name
      };
      await AdminService.sendTestSms(testSmsPayload);
    } else if (
      this.editedItem.name === 'sms_template_round_up_summary' ||
      this.editedItem.name === 'sms_template_round_up_transaction'
    ) {
      if (!this.testAmount) {
        this.isSendingTestSMS = false;
        this.$notifyError('All test sms inputs are required');
      }

      const testSmsPayload = {
        sms: this.editedItem.value,
        smsParams: { roundedAmount: Number(this.testAmount) },
        smsType: this.editedItem.name
      };
      await AdminService.sendTestSms(testSmsPayload);
    }
    this.isSendingTestSMS = false;
  }

  async created(): Promise<void> {
    this.getGlobalSettings();
  }

  async syncOffers(): Promise<void> {
    this.syncLoading = true;
    try {
      await AdminService.sync();
    } catch (e: any) {
      this.$notifyError('Something went wrong');
      this.syncLoading = false;
    }
    this.syncLoading = false;
  }
}
