import { CorporateMatchingType } from './corporates.types';

export enum MultipleCauseType {
  OFFER = 'offer',
  ROUND_UP = 'roundup',
  BOTH = 'both'
}

export interface IAdminCorporate {
  id: string;
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  coverImg: string;
  createdAt: string;
  description: string;
  lastSyncAt: string;
  logoUrl: string;
  name: string;
  phone: string;
  postCode: string;
  priority: number;
  state: string;
  title: string;
  updatedAt: string;
  websiteUrl: string;
  matchingType: CorporateMatchingType;
}

export interface IAdminOffer {
  brand: any;
  id: string;
  budget: string;
  coverImg: string;
  createdAt?: string;
  currency?: string;
  daysAvailability: string;
  description: string;
  purchaseAmount: string;
  purchaseFrequency: number;
  qualifier: string;
  reach: string;
  redeemLimitPerUser: number;
  redemptionTrigger: string;
  redemptionType: string;
  rewardMaxValue: string;
  rewardType: string;
  rewardValue: string;
  startDate: string;
  tile: string;
  title: string;
  totalRedeemLimit: number;
  updatedAt: string;
}

export interface IBrands {
  id: string;
  createdAt: string;
  lastSyncAt: string;
  logoUrl: string;
  name: string;
  priority: number;
  updatedAt: string;
  websiteUrl: string;
}

export interface ICorporatePayload {
  logo: File | null;
  coverImage: File | null;
  priority: number;
}

export interface IEmailCustomResponse {
  id?: string | null;
  subject: string;
  body: string;
  offerId?: string | null;
}

export interface IGlobalSettings {
  emailSubject: string | null;
  additionalInfo: string | null;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  description: string | null;
  name: string;
  type: string;
  value: string;
  multipleCauseType: MultipleCauseType | null;
}

export interface IGlobalSettingUpdate {
  value: string;
  emailSubject: string | null;
  multipleCauseType: MultipleCauseType | null;
}

export interface IPaymentCharge {
  id: string;
  chargedTotal: number;
  currencyCode: string;
  status: string;
  userId: string;
  firstName: string;
  lastName: string;
  chargeDate: Date;
}

export interface IPaymentChargeRequest {
  startDate: Date;
  endDate: Date;
}

export interface IOfferRedeemedRequest {
  startDate: Date;
  endDate: Date;
}

export interface ISmsCustomResponse {
  id?: string;
  sms: string;
  offerId?: string;
}

export interface ITestEmailPayload {
  body: string;
  emailParams: any;
  emailType: string;
  subject: string;
}

export interface ITestSmsPayload {
  sms: string;
  smsParams: any;
  smsType: string;
}

export interface IAdminSettingsImagesResponse {
  background?: string;
  overlay?: string;
  banner?: string;
  footerText?: string;
  icon1?: string;
  link1?: string;
  icon2?: string;
  link2?: string;
  icon3?: string;
  link3?: string;
  icon4?: string;
  link4?: string;
}

export interface IMultipleCauseEnabled {
  isMultipleCauseEnabled: boolean;
}

export interface IForm {
  background: null;
  overlay: null;
  banner: null;
  onboardBackground: null;
  onboardOverlay: null;
  onboardBanner: null;
  footerText: string | null;
  icon1: null;
  link1: string | null;
  icon2: null;
  link2: string | null;
  icon3: null;
  link3: string | null;
  icon4: null;
  link4: string | null;
}
